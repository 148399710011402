import {
    commonTwilioMixin
} from "./common-bedelia-mixin.js";

import ivbus from "@/utils/ivbus.js";
import apiClient from "@/utils/apiClient.js";

var slicer = function (paginator, array) {
    // update paginator
    paginator.total = array.length;
    paginator.total_pages = Math.ceil(paginator.total / paginator.per_page);
    if (paginator.current_page > paginator.total_pages) {
        paginator.current_page = paginator.total_pages;
    }
    if (paginator.current_page == 0) {
        paginator.current_page = 1;
    }

    return array.slice(paginator.per_page * (paginator.current_page - 1), paginator.per_page * paginator.current_page);
}

export var orgTwilioMixin = {
    mixins: [commonTwilioMixin],
    data() {
        return {
            candidateAutoReplyEnabled: false,
            candidateAutoReplyMessage: "",
            twilioAutoreplyUrl: "/api/twilio/autoreply/",
            showAutoReplyEditor: false,
            channelPagination: {
                total: 0,
                per_page: 25,
                current_page: 1,
                total_pages: 1
            }
        }
    },

    computed: {
        ChannelListSorted: function () {
            var that = this;
            return slicer(that.channelPagination, that.channelsList.sort(function (a, b) {
                // desired: first sort by latest received message, then by latest sent message if the student has never responded
                // actual for now: sort by latest received message if student sent most recent message, then by latest sent message if org sent most recent message

                // consider sorting channels with unread messages higher

                var atime = a.last_message_sent_by == that.identity ? "xxx" + a.last_message_sent_at : "yyy" + a.last_message_sent_at;
                var btime = b.last_message_sent_by == that.identity ? "xxx" + b.last_message_sent_at : "yyy" + b.last_message_sent_at;

                return (atime < btime ? 1 : (atime > btime ? -1 : 0));
            }));
        }
    },
    mounted: function () {
        var that = this;

        // this is where we can implement our logic about making the first channel UI-responsive etc

        // TODO create a version of this for ivee
        ivbus.$on('bedeliaChatOpened', function (student) {
            ivbus.getFlag('twilioClient').then(function (client) {
                // at this point we're committed to opening this particular channel
                that.bedeliaSpinId = student.spinId;

                // 1) the channel is already cached (ie we've opened the modal before)
                if (that.ivTwilio.subscribedChannels[student.channel_sid]) {
                    that.selectChannel(that.ivTwilio.subscribedChannels[student.channel_sid]);
                    return;
                }
                // 2) we get channel_sid, user_identity, and user_friendly_name and can retrive and cache the channel
                if (student.channel_sid && student.other_identity && student.other_friendly_name) {
                    that.ivTwilio.client.getChannelBySid(student.channel_sid).then(function (channel) {
                        that.initializeAndSelectChannel(channel, student.friendly_name);
                    }).catch(function (error) {
                        console.log(error);
                    });
                    return;
                }
                // 3) we get user_identity and user_friendly_name and need to create the channel
                if (student.other_identity && student.other_friendly_name) {
                    var identity = {
                        identity: student.other_identity,
                        friendly_name: student.other_friendly_name
                    };
                    that.createChannel(identity, true).then(function (channel) {
                        that.initializeAndSelectChannel(channel, student.friendly_name);
                    }).catch(function (error) {
                        console.log(error);
                    });
                    return;
                }

            });

        });

        $('#bedelia-inbox').popover({
            placement: "bottom",
            html: true,
            content: $("#bedelia-inbox-content").on('show.bs.popover', function () { // TODO: um, can we not use v-show for this?
                $('#bedelia-inbox-content').removeClass('hide')
            }).on('hide.bs.popover', function () {
                $('#bedelia-inbox-content').addClass('hide')
            })
        });

        // now that the twilio client and cache are ready, we can do org-specific initializations (we could also have sections that wait for only one or the other)
        Promise.all([ivbus.getFlag('twilioClient'), ivbus.getFlag('twilioState')]).then(function () {

        });
    },
    methods: {
        noop: function () {},
        openChatModal: function (iv) {

            var that = this;
            that.closePopover();
            that.bedeliaChatOpen = true;
            var spinId = that.spinStart({
                spinkey: "bedelia"
            });

            // this method could be called by an interview object, which has sendId and also sid, identity, and friendly_name if they exist in the server cache
            // or by a channelInfo object, which does not have sendId but is guaranteed to have sid, identity, and friendly_name set

            ivbus.setFlag('bedeliaChatOpen', iv.sendId || iv.channel_sid);

            Promise.all([ivbus.getFlag('twilioClient'), ivbus.getFlag('twilioState')]).then(function () {

                var checkAndOpen = function (openTwilioInfo) {
                    ivbus.getFlag('bedeliaChatOpen').then(function (openedModal) {
                        if (openedModal == iv.sendId || iv.channel_sid) {
                            ivbus.$emit('bedeliaChatOpened', openTwilioInfo);
                        } else {
                            that.spinStop(spinId);
                        }
                    });
                }

                // if our user_identity and user_friendly_name don't exist, create them before sending our info to the modal
                if (!(iv.other_identity && iv.other_friendly_name)) {
                    apiClient.get("/api/twilio/user_identity/" + "?send=" + iv.sendId).then(function (response) {
                        var newTwilioInfo = {
                            channel_sid: iv.channel_sid,
                            other_identity: response.data.other_identity,
                            other_friendly_name: response.data.other_friendly_name,
                            spinId: spinId
                        }
                        //ivbus.setFlag('twilioInfo', newTwilioInfo); // lets try to not need this
                        checkAndOpen(newTwilioInfo);
                    });
                } else {
                    checkAndOpen({
                        channel_sid: iv.channel_sid,
                        other_identity: iv.other_identity,
                        other_friendly_name: iv.other_friendly_name,
                        spinId: spinId
                    });
                }

            });

        },

        closeBedeliaChat: function () {
            this.bedeliaChatOpen = false;
            this.renderableChannel = null;
            ivbus.setFlag('bedeliaChatOpen', undefined);
        },

        closePopover: function () {
            $('#bedelia-inbox').popover('hide');
        },

        createChannel: function (identity, add, autoReply) {
            var that = this;
            return new Promise(function (resolve, reject) {

                var resolveChannel = function (channel) {
                    var promises = [channel.join()];
                    if (add) {
                        promises.push(channel.add(identity.identity));
                    }

                    Promise.all(promises).then(function () {
                        console.log("joined channel");
                        identity.channel = channel;
                        resolve(channel);
                    });
                }

                that.ivTwilio.client.createChannel({
                    friendlyName: that.friendlyName + " - " + identity.friendly_name,
                    uniqueName: that.identity + ":" + identity.identity,
                    isPrivate: true,
                    attributes: {
                        "autoUnconsumedCount": 0
                    }
                }).then(resolveChannel).catch(function (error) {
                    console.log(error); // TODO: check if it is a channel-with-this-unique-name-already-exists error type before blindly recovering
                    that.getChannelByIdentity(identity.identity, identity.friendly_name).then(resolveChannel).catch(function (error) {
                        console.log(error);
                    });
                });

            });
        },

        getChannelByIdentity: function (identity, name) {
            var that = this;
            return new Promise(function (resolve, reject) {

                that.ivTwilio.client.initialize().then(function () {
                    // if the channel doesn't exist, it returns null and is created in the controller's callback
                    var uniqueName = (that.identity + ":" + identity);
                    that.ivTwilio.client.getSubscribedChannels().then(function (channelPaginator) {

                        var channel = null;

                        var checkItems = function (paginator) {
                            paginator.items.forEach(function (c) {
                                if (!channel && c.uniqueName === uniqueName) {
                                    channel = c;
                                }
                            });
                            if (!channel && paginator.hasNextPage) {
                                paginator.nextPage().then(function (newPaginator) {
                                    checkItems(newPaginator);
                                });
                            } else {
                                if (channel) {
                                    resolve(channel);
                                } else {
                                    reject(channel);
                                }
                            }
                        }

                        checkItems(channelPaginator);

                    });
                });

            });
        },

        handleSendMessage: function (sendText) {
            this.sendMessage(sendText, this.ivTwilio.currentChannel);
        },

        saveAutoReply: function (body) {
            var that = this;

            var spinId = that.spinStart({
                spinkey: "bedelia"
            });
            apiClient.post(that.twilioAutoreplyUrl, body).then(function (updatedChannel) {
                that.spinStop(spinId);
                that.renderableChannel.autoReplyEnabled = updatedChannel.data.data.autoReplyEnabled;
                that.renderableChannel.autoReplyMessage = updatedChannel.data.data.autoReplyMessage;
            });
        },        

    }
}