<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <div id="hsp-alert-parent" aria-multiselectable="true">
                <div>
                    <a data-toggle="collapse" data-parent="#hsp-alert-parent" href="#hspalert" @click="alert_seen">
                        <div class="panel-title alert-title">
                            <span style="margin-left:12px">Important Disclaimer</span>
                        </div>
                    </a>
                    <div id="hspalert" class="panel-collapse collapse" v-bind:class="{ 'in': alertSeen() }">
                        <div class="panel-body" style="margin:-15px">
                            <div class="alert-hsprofile">
                                <span class="alert-gray-text">This high school profile is for the school and curriculum track that the student selected when they registered for their InitialView interview. </span>
                                <span class="alert-black-text">Despite our best efforts to ensure student accuracy when self-reporting their information, occasionally they still make errors. </span>
                                <span class="alert-gray-text">In the event that this profile is not consistent with other materials that you have received from this student, please follow up independently so that you can </span>
                                <span class="alert-black-text"> make your own determination as to whether or not the discrepancy is the result of an honest mistake.</span>
                                <button class="alert-hsprofile-button" data-toggle="collapse"
                                    data-parent="#hsp-alert-parent" href="#hspalert" @click="alert_seen">Got it! Please
                                    don't show me this anymore.</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="hsprofile.cbo_profile_img" class="text-center" style="padding:15px 0;">
        <img class="img-responsive" :src="hsprofile.cbo_profile_img">
    </div>
    <div v-else>
        <div class="row row-short">
            <div class="col-md-9">
                <div class="bluetext">{{hsprofile.hs_name}}</div>
            </div>
            <div class="col-md-3 orangetext text-right" v-show="hsprofile.profile">
                <a v-bind:href="hsprofile.profile" target="_blank"><span class="glyphicon glyphicon-download-alt"></span> Official School Profile</a>
            </div>
        </div>
        <div class="row row-short">
            <div class="col-md-3 orangetext">
                CEEB Code: {{hsprofile.ceeb_code}}
            </div>
            <div class="col-md-3 orangetext" v-show="hsprofile.iv_code">
                IV Code: {{hsprofile.iv_code}}
            </div>
            <div class="col-md-6 orangetext">
                <img src="https://initialview.com/ivgcs/img/hsp-icon-website.svg" style="width:25px" />
                <a v-bind:href="httpize(hsprofile.website)" target="_blank" v-show="hsprofile.website != 'N/A'">{{hsprofile.website}}</a>
                <span v-show="hsprofile.website == 'N/A'">{{hsprofile.website}}</span>
            </div>
        </div>
        <div class="row row-short">
            <div class="col-md-12" v-if="hsprofile.rankings">
                <span style="color: #003349;font-size: 1.5em;font-weight: bold;">#{{hsprofile.rankings.rank}} of {{hsprofile.rankings.rank_out_of}}</span>
                <span style="color:#e96724;text-transform:uppercase;font-size: 1.25em;"> in high school comparison ranking within {{hsprofile.rankings.rank_grouping}} </span>
                <span style="color: #003349;font-size: 1.25em;font-weight: bold;text-transform:uppercase;text-decoration:underline;cursor:pointer;" v-on:click="openModal('ranking-list')">View List</span>
                <a href="/understanding_China_high_schools#ranking" target="_blank"><span class="glyphicon glyphicon-info-sign"></span></a>
            </div>
        </div>
        <div class="row row-m0">
            <div class="col-md-12 bluebannertext">
                <span v-if="iveeData.curriculum" style="margin-left:10px"> <i>{{iveeData.user}}'s Curriculum Info: {{iveeData.curriculum || "[student didn’t select]"}}</i> </span>
            </div>
        </div>
        <div class="row" style="margin-top:0">
            <div class="col-md-8" style="padding:0;">
                <!-- <div style="display:flex">-->
                <div class="maincontent">
                    <div class="graytext" v-show="hsprofile.curricula.length > 0">
                        <i>See COURSE OFFERINGS below for other options available at this school</i>
                    </div>
                    <div style="background-color: #fff; padding:10px;">
                        <div class="row row-short" style="margin-top:0px;color: #003349;font-size: 1.5em;font-weight: bold;">
                                <div class="col-md-12" style="margin-left:10px;">
                                CONTACT INFORMATION
                            </div>
                        </div>
                        <div class="row row-short" style="margin-bottom:0" v-if="hsprofile.contacts && hsprofile.contacts.length == 0">
                            <!--no counselor listed-->
                            <div class="col-md-12 counselor-container" v-bind:style="hsprofile.demographics ? '' : 'padding-bottom: 15px'">
                                <span class="counselor-info" v-show="hsprofile.emails.length">
                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-email1.svg" style="width:20px" />
                                    {{hsprofile.emails[0]}}
                                </span>
                                <span class="counselor-info" v-show="hsprofile.phones.length" v-bind:class="hsprofile.emails[0] ? 'pull-right' : ''">
                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-phone.svg" style="width:20px" />
                                    {{hsprofile.phones[0]}}
                                </span>
                            </div>
                        </div>
                        <div class="row row-short" style="margin-bottom:0" v-if="hsprofile.contacts && hsprofile.contacts.length == 1">
                            <!--just one counselor-->
                            <div class="col-md-12 counselor-container" v-bind:style="hsprofile.demographics ? '' : 'padding-bottom: 15px'">
                                <div class="counselor-info">
                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-people1.svg" style="width:25px" />
                                    <b>{{hsprofile.contacts[0].first_name}} <span v-if="hsprofile.contacts[0].counselor_aka">({{hsprofile.contacts[0].counselor_aka}})</span> {{hsprofile.contacts[0].last_name}}</b><span v-show="hsprofile.contacts[0].counselor_title">, {{hsprofile.contacts[0].counselor_title}}</span>
                                </div>
                                <div class="counselor-info">
                                    <span>
                                        <img src="https://initialview.com/ivgcs/img/hsp-icon-email1.svg"
                                            style="width:20px" />
                                        {{hsprofile.contacts[0].email}}
                                    </span>
                                    <span v-bind:class="hsprofile.contacts[0].email ? 'pull-right' : ''">
                                        <img src="https://initialview.com/ivgcs/img/hsp-icon-phone.svg"
                                            style="width:20px" />
                                        {{hsprofile.phones[0]}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row row-short" style="margin-bottom:0" v-if="hsprofile.contacts && hsprofile.contacts.length > 1">
                            <!--more than one counselor, just use the first two -->
                            <div class="col-md-12 counselor-container" v-bind:style="hsprofile.demographics ? '' : 'padding-bottom: 15px'">
                                <div class="counselor-info">
                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-people1.svg" style="width:25px" />
                                    <b>{{hsprofile.contacts[0].first_name}} <span v-if="hsprofile.contacts[0].counselor_aka">({{hsprofile.contacts[0].counselor_aka}})</span> {{hsprofile.contacts[0].last_name}}</b><span v-show="hsprofile.contacts[0].counselor_title">, {{hsprofile.contacts[0].counselor_title}}</span>;
                                    <b>{{hsprofile.contacts[1].first_name}} <span v-if="hsprofile.contacts[1].counselor_aka">({{hsprofile.contacts[1].counselor_aka}})</span> {{hsprofile.contacts[1].last_name}}</b><span v-show="hsprofile.contacts[1].counselor_title">, {{hsprofile.contacts[1].counselor_title}}</span>
                                </div>
                                <div class="counselor-info">
                                    <span>
                                        <img src="https://initialview.com/ivgcs/img/hsp-icon-email1.svg"
                                            style="width:25px" />
                                        {{hsprofile.contacts[0].email}}, {{hsprofile.contacts[1].email}}
                                    </span>
                                    <span class="pull-right">
                                        <img src="https://initialview.com/ivgcs/img/hsp-icon-phone.svg"
                                            style="width:25px" />
                                        <!-- +{{hsprofile.phones[0].substring(0,2)}}-{{hsprofile.phones[0].substring(2,5)}}-{{hsprofile.phones[0].substring(5,9)}}-{{hsprofile.phones[0].substring(9,13)}} -->
                                        {{hsprofile.phones[0]}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" style="margin-top:0" v-show="hsprofile.demographics">
                        <transition name="fade">
                            <div v-if="popover_clicked" class="data-popover">
                                <div v-if="!city_comparison">
                                    <div v-if="popover_population" class="data-population popover-content">
                                        <span class="popover_city">{{hsprofile.demographics.name}}</span>
                                        <hr style="border: 1px #e96724 solid; margin: 10px 0px">
                                        <div style="display:flex">
                                            <div>
                                                <div>
                                                    <div class="pop_title">POPULATION</div>
                                                    <div class="popover_text">{{formatNumberString(hsprofile.demographics.population)}}
                                                        ({{hsprofile.demographics.population_year}})</div>
                                                </div>
                                                <div v-show="hsprofile.demographics.gdp > 0">
                                                    <div class="gdp_title">CITY GDP PER CAPITA</div>
                                                    <div class="popover_text">{{formatNumberString(hsprofile.demographics.gdp)}}
                                                        ({{hsprofile.demographics.gdp_year}})</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="popup-button-holder" @click="compare_pop">
                                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-list.svg"
                                                        style="width:25px" />
                                                    <div class="compare-button-text">
                                                        Compare to Other Cities
                                                    </div>
                                                </div>
                                                <div class="popup-button-holder" @click="compare_gdp" v-show="hsprofile.demographics.gdp > 0">
                                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-list.svg"
                                                        style="width:25px" />
                                                    <div class="compare-button-text">
                                                        Compare to Other Cities
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popover_gdp" class="data-gdp popover-content">
                                        <span class="popover_city">{{hsprofile.demographics.name}}</span>
                                        <hr style="border: 1px #83a19e solid; margin: 10px 0px">
                                        <div style="display:flex">
                                            <div>
                                                <div>
                                                    <div class="pop_title">POPULATION</div>
                                                    <div class="popover_text">{{formatNumberString(hsprofile.demographics.population)}}
                                                        ({{hsprofile.demographics.population_year}})</div>
                                                </div>
                                                <div v-show="hsprofile.demographics.gdp > 0">
                                                    <div class="gdp_title">CITY GDP PER CAPITA</div>
                                                    <div class="popover_text">{{formatNumberString(hsprofile.demographics.gdp)}}
                                                        ({{hsprofile.demographics.gdp_year}})</div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="popup-button-holder" @click="compare_pop">
                                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-list.svg"
                                                        style="width:25px" />
                                                    <div class="compare-button-text">
                                                        Compare to Other Cities
                                                    </div>
                                                </div>
                                                <div class="popup-button-holder" @click="compare_gdp" v-show="hsprofile.demographics.gdp > 0">
                                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-list.svg"
                                                        style="width:25px" />
                                                    <div class="compare-button-text">
                                                        Compare to Other Cities
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="city_comparison">
                                    <div v-if="popover_population" class="popover-content data-population">
                                        <div style="display:flex">
                                            <div @click="exit_compare">
                                                <span class="glyphicon glyphicon-menu-left exit-compare-icon"></span>
                                            </div>
                                            <div style="margin: -5px 10px">
                                                <div class="popover_city">
                                                    {{hsprofile.demographics.name}}
                                                </div>
                                                <div class="compare-text-orange">
                                                    POPULATION COMPARISON BY CITY
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px #e96724 solid; margin: 10px 0px">
                                        <!--
                                        <div style="margin: -5px 0px; color:#303b49">
                                            City populations
                                        </div>
                                        <hr style="border: 1px #e96724 solid; margin: 10px 0px">
                                        -->
                                        <div class="comparison-lists">
                                            <div style="margin:5px 45px">
                                                <div v-for="city in sortedByPopulation" class="listed-text">
                                                    <span style="text-transform:uppercase"><span
                                                            v-bind:class="{ 'compare-text-orange' : currentCity(city.filler_name) }">{{ city.filler_name }}</span></span>
                                                </div>
                                            </div>
                                            <div style="margin:5px 65px">
                                                <div v-for="city in sortedByPopulation" class="listed-text">
                                                    <span
                                                        v-bind:class="{ 'compare-text-orange' : currentCity(city.filler_name) }">{{ city.filler_population }}
                                                        ({{city.filler_population_year}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popover_gdp" class="popover-content data-gdp">
                                        <div style="display:flex">
                                            <div @click="exit_compare">
                                                <span class="glyphicon glyphicon-menu-left exit-compare-icon"></span>
                                            </div>
                                            <div style="margin: -5px 10px">
                                                <div class="popover_city">
                                                    {{hsprofile.demographics.name}}
                                                </div>
                                                <div>
                                                    GDP COMPARISON BY CITY (USD PER CAPITA)
                                                </div>
                                            </div>
                                        </div>
                                        <hr style="border: 1px #83A19e solid; margin: 10px 0px">
                                        <div class="comparison-lists">
                                            <div style="margin:5px 45px">
                                                <div v-for="city in sortedByGDP" class="listed-text">
                                                    <span style="text-transform:uppercase"><span
                                                            v-bind:class="{ 'compare-text-blue' : currentCity(city.filler_name) }">{{ city.filler_name }}</span></span>
                                                </div>
                                            </div>
                                            <div style="margin:5px 65px">
                                                <div v-for="city in sortedByGDP" class="listed-text">
                                                    <span
                                                        v-bind:class="{ 'compare-text-blue' : currentCity(city.filler_name) }">{{ city.filler_gdp }}
                                                        ({{city.filler_gdp_year}})</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <div class="col-md-12">
                            <div style="position:absolute;top:0;height:200px;width:777px;background: #fff;"></div>
                            <div id="mapsvgid" class="map-background">
                                <img v-if="popover_population"
                                        src="https://initialview.com/ivgcs/img/hsp-icon-map-pin-orange.svg"
                                        class="map-icon" />
                                <img v-if="popover_gdp"
                                        src="https://initialview.com/ivgcs/img/hsp-icon-map-pin-turquoise.svg"
                                        class="map-icon" />
                                <span id="hscitylabelid"></span>
                                <svg width="600" height="450" v-on:click="togglePopover()"></svg>
                                <div style="position:absolute; top:10px; left: 10px; right: 10px; padding: 5px; background-color:#dddd;">
                                    <!-- TITLE DIV -->
                                    <div v-if="popover_population">
                                        <span class="map_pop_title">
                                            Population
                                        </span>
                                        <span v-show="hsprofile.demographics && hsprofile.demographics.gdp" class="click_gdp" @click="toggleGDP_POP">
                                            SEE GDP MAP &gt;&gt;
                                        </span>
                                    </div>
                                    <div v-if="popover_gdp">
                                        <span class="map_gdp_title">
                                            Per Capita GDP (USD)
                                        </span>
                                        <span class="click_pop" @click="toggleGDP_POP">
                                            SEE POPULATION MAP &gt;&gt;
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="hsprofile.cadenza" style="position: relative; padding:15px;">
                        <!-- hear from counselor-->
                        <div class="sidebarheading">
                            HEAR FROM THE COUNSELOR:
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <IVPlayer customid="hsp" :eventns="eventns"></IVPlayer>
                                <div class="panel panel-blue" style="margin-top:15px;">
                                    <div class="panel-heading">
                                        <h3 class="panel-title text-center">SEND COUNSELOR A MESSAGE</h3>
                                    </div>
                                    <div class="panel-body" style="margin:0">
                                        <div>From:</div>
                                        <input style="display:inline-block;width:49%;margin-bottom:10px;" class="form-control" v-model="messageFromName" placeholder="Your Name">
                                        <input style="display:inline-block;width:50%;margin-bottom:10px;" class="form-control" v-model="messageFrom" placeholder="Your Email">
                                        <textarea
                                            class="form-control"
                                            rows="4"
                                            v-model="messageContent"
                                            placeholder="Message"
                                            style="border: 2px solid #777;border-radius: 12px;"
                                        ></textarea>
                                        <div v-show="!messageSent">
                                            <div style="font-style: italic;" class="text-center">A copy of this message will be CC'd to you</div>
                                            <div
                                                class="btn btn-blue pull-right"
                                                v-on:click="sendMessage()"
                                                >Send</div>
                                        </div>
                                        <div v-show="messageSent">
                                            <div style="font-style: italic;" class="text-center">Message sent!</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style="padding:0px">
                                <div id='questions' style="margin:0px; overflow-y: hidden; height:unset !important">
                                    <ul style="list-style-type: none; margin-right: 1em; padding-left: 20px;">
                                        <li v-for='q in videometa.ivVideoData.questions'
                                            v-on:click.prevent='selectQuestion(q)'>
                                            <div>
                                                <div style="position:absolute">
                                                    <img src="https://initialview.com/ivgcs/img/hsp-icon-video-blue.svg"
                                                        class="blue-cam" />
                                                    <div v-bind:class="q.class">
                                                        <p class="blue-cam-number">{{q.index}}</p>
                                                    </div>
                                                </div>
                                                <div id='questionParent'
                                                    v-bind:class="q.class + (q.index == currentVideoQuestionId ? ' live' : '')"
                                                    v-bind:data-question-index='q.index'>
                                                    <div class='question'>
                                                        <b>({{ Math.floor(q.time / 60) }}:{{(q.time % 60) < 10 ? '0' + (q.time % 60) : (q.time % 60) }})</b>
                                                        {{q.text}}</div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4" style="padding:0;">
                <div class="sidebar">
                    <div>
                        <div class="sidebarheading" style="margin-top:0;padding-top:5px;">
                            {{hsprofile.country}}
                        </div>
                    </div>
                    <div>
                        <div class="sidebarheading">
                            CAMPUS ADDRESS
                        </div>
                        <div class="sidebartext">
                            {{hsprofile.address}}
                        </div>
                    </div>
                    <div>
                        <div class="sidebarheading">
                            SCHOOL TYPE
                        </div>
                        <div class="sidebartext">
                            {{hsprofile.department_type}}
                        </div>
                        <div v-show="hsprofile.curriculum_note" class="sidebartext" style="font-style:italic;">
                            {{hsprofile.curriculum_note}}
                        </div>
                    </div>
                    <div v-show="hsprofile.founded">
                        <div class="sidebarheading">
                            YEAR FOUNDED
                        </div>
                        <div class="sidebartext">
                            <div><b>{{hsprofile.founded}}</b> <span v-show="hsprofile.other_departments && hsprofile.other_departments.length"> {{hsprofile.department_type}}</span></div>
                            <div v-for="other in hsprofile.other_departments" v-show="other.founded"><b>{{other.founded}}</b> {{other.department_type}}</div>
                        </div>
                    </div>
                    <div v-show="hsprofile.enrollment || hsprofile.enrollment2020">
                        <div class="sidebarheading">
                            ENROLLMENT
                        </div>
                        <div class="sidebartext">
                            <div v-show="hsprofile.enrollment"><b>{{hsprofile.enrollment}}</b> Total</div>
                            <div v-show="hsprofile.enrollment2020"><b>{{hsprofile.enrollment2020}}</b> Graduating Seniors</div>
                        </div>
                    </div>
                    <div v-show="hsprofile.curricula.length > 0">
                        <div class="sidebarheading">
                            COURSE OFFERINGS
                        </div>
                        <div class="panel-group" style="margin-bottom:0;" id="cur-tracks" aria-multiselectable="true">
                            <div v-for="(cur, index) in hsprofile.curricula" v-bind:key="index">
                                <div class="cur-wrapper">
                                    <a data-toggle="collapse" data-parent="#cur-tracks" :href="'#' + cur.label.replace(/\s/g,'')"
                                        @click="logo_workaround(index, $event)">
                                        <div class="panel-title bluebannertext curricula">
                                            <span style="margin-left:12px">{{cur.label}}</span>
                                            <span v-show="cur_tracks[index] == true"
                                                class="glyphicon glyphicon-triangle-top cur-icon"></span>
                                            <span v-show="cur_tracks[index] == false"
                                                class="glyphicon glyphicon-triangle-bottom cur-icon"></span>
                                        </div>
                                    </a>
                                    <div :id="cur.label.replace(/\s/g,'')" class="panel-collapse collapse" style="position:relative;">
                                        <div class="panel-body" style="overflow-y:scroll;white-space:pre-line;" v-bind:style="{'max-height': (hsprofile.curricula.length > 1 ? 250 : 500) + 'px'}" v-on:scroll="checkScroll">
                                            <div v-for="course in cur.courses">
                                                <div class="courses">{{course.course}}</div>
                                            </div>
                                        </div>
                                        <div style="position:absolute;bottom:0;width:100%;display:none;" class="text-center scroll-chevron">
                                            <span class="glyphicon glyphicon-chevron-down"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-show="hsprofile.external_note || publishedOtherDepts.length" style="padding-bottom:10px;border-top:1px solid #e96724;">
                        <div class="sidebarheading" style="margin-top:5px;">
                            OTHER NOTES
                        </div>
                        <div class="sidebartext" v-for="other in publishedOtherDepts">
                            <a style="cursor:pointer" v-on:click="loadOtherProfile(other)">See {{other.department_type}} Profile &gt;</a>
                        </div>
                        <div class="sidebartext" style="font-style:italic;">
                            {{hsprofile.external_note}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" style="height:45px; background-color:#003349; margin-bottom:0; border-radius: 0 0 10px 10px;">
            <div class="col-md-12">
            </div>
        </div>
        <div v-if="hsprofile.rankings" class="modal fade" id="ranking-list" tabindex="-1" role="dialog" aria-hidden="true" style="z-index:1000001;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content" style="border-radius:0px;">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                        <div class="text-center" style="color:#e96724;text-transform:uppercase;font-size: 1.25em;">High School Comparison Ranking Within {{hsprofile.rankings.rank_grouping}}</div>
                    </div>
                    <div class="modal-body" style="overflow-y: scroll;">
                        <div class="row">
                            <div class="col-md-12">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                Rank
                                            </th>
                                            <th>
                                                English Name
                                            </th>
                                            <th>
                                                Chinese Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="ranking in hsprofile.rankings.ranking_list" v-bind:class="ranking.label == hsprofile.hs_name ? 'info' : ''">
                                            <td>
                                                {{ranking.rank}}
                                            </td>
                                            <td>
                                                {{ranking.label}}
                                            </td>
                                            <td>
                                                {{ranking.label_cn}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>

    import Vue from 'vue';
    import VueResource from 'vue-resource';
    Vue.use(VueResource);
    import * as d3 from "d3";
    import * as topojson from "topojson-client";


    import {
        ivSpinnerMixin,
        ivModalMixin,
        getParameterByName
    } from "@/mixins/base-components-pack.js";

    import env from "../utils/env.js";
    import ivbus from "../utils/ivbus.js";
    import apiClient from "../utils/apiClient.js";
    import IVPlayer from '@/components/IVPlayer.vue';
    /**
     * The constant to scale by for different cities. Default value is 600, which works well for Chinese cities.
     */
    var RADIUS_CONSTANT = 600
    var RADIUS_CONST_EUROPE = 22

    var popFnWithCoords = function (raw, coord) {
        if (coord < 60) {
            return raw > 0 ? Math.max(Math.cbrt(raw) / RADIUS_CONST_EUROPE, 3) : 0;
        }
        else {
            console.log("hello")
            return raw > 0 ? Math.max(Math.sqrt(raw) / RADIUS_CONSTANT, 2) : 0;
        }
    }
    var popFnWithScalar = function (raw, s) {
        return raw > 0 ? Math.max(Math.sqrt(raw) / s, 2) : 0;
    }
    var popFn = function (raw) {
        return raw > 0 ? Math.max(Math.sqrt(raw) / RADIUS_CONSTANT, 2) : 0; // sometimes changed from 600 to 200 for testing purposes.
    }
    var gdpFn = function(raw) {
        return raw > 0 ? Math.max(Math.sqrt(raw) / 25, 2) : 0;
    }
    export default {
        props: [
            'hsprofile',
            'iveeData',
            'curLength',
        ],
        components: {
            IVPlayer
        },
        mixins: [
            ivSpinnerMixin,
            ivModalMixin
        ],
        data() {
            return {
                cur_tracks: [],
                alertclickedhsp: false,
                i: 0,
                popover_clicked: false, //is the popover open?
                popover_population: true, //is the population map showing?
                popover_gdp: false, //is the gdp map showing?
                city_comparison: false, //are we comparing cities in the popover?
                code_orange: "#e96724",
                code_orange_light: "#996634", //"#F58C0F",
                code_blue: "#83a19e",
                code_blue_light: "#5b7276",
                hs_city: [],
                population_list: [],
                gdp_list: [],

                eventns: "-hsp",
                videometa: {
                    status: 0,
                    star: false,
                    recruited: false,
                    ivVideo: 'https://initialview.com/ivgcs/video/blank.mp4',
                    ivVideoData: {
                        questions: []
                    },
                    user: '',
                    ivVideo: '',
                    org: '',
                    message: 'Loading...',
                    date: '',
                    ivType: '',
                    block_video: false
                },
                currentVideoQuestionId: 0,

                messageFromName: "",
                messageFrom: "",
                messageContent: "",
                messageSent: false
            }
        },
        created: function () {
            ivbus.$on('iv-player-current-question' + this.eventns, this.setCurrentVideoQuestionId)
            ivbus.$on('iv-player-mounted' + this.eventns, this.fetchVideoMetadata)
        },
        computed: {
            sortedByPopulation: function() {
                var that = this;
                that.population_list = JSON.parse(JSON.stringify(that.hsprofile.demographics.filler));
                function compare(a, b) {
                if (+a.filler_population > +b.filler_population)
                    return -1;
                if (+a.filler_population < +b.filler_population)
                    return 1;
                return 0;
                }
                var sorted = this.population_list.filter(function (c) { return c.include_in_compare; }).sort(compare);
                sorted.forEach(function(item) {
                    item.filler_population = that.formatNumberString(item.filler_population);
                });

                return sorted;
            },
            sortedByGDP: function() {
                var that = this;
                that.gdp_list = JSON.parse(JSON.stringify(that.hsprofile.demographics.filler));
                function compare(a, b) {
                if (+a.filler_gdp > +b.filler_gdp)
                    return -1;
                if (+a.filler_gdp < +b.filler_gdp)
                    return 1;
                return 0;
                }
                var sorted = this.gdp_list.filter(function (c) { return c.include_in_compare && +c.filler_gdp > 0; }).sort(compare);
                sorted.forEach(function(item) {
                    item.filler_gdp = that.formatNumberString(item.filler_gdp);
                });

                return sorted;
            },
            noContacts: function() {
                if (this.hsprofile.contacts.length == 0) {
                    return true
                }
                else {
                    return false
                }
            },
            publishedOtherDepts: function () {
                return this.hsprofile.other_departments ? this.hsprofile.other_departments.filter(function(hsp) { return hsp.published; }) : [];
            }
        },
        mounted () {
            var that = this;
            ivbus.getFlag("user").then(function(user) {
                that.messageFrom = user.email;
                that.messageFromName = user.first_name + " " + user.last_name;
            });

        apiClient.get("/topology.json").then(function(response) {

            var south_america = response.data.south_america;
            var china = response.data.china;
            var africa = response.data.africa;
            var asia = response.data.asia;
            var europe = response.data.europe;
            var north_america = response.data.north_america;
            var oceania = response.data.oceania;

            ivbus.getFlag("hsprofile").then(function () {

                while (that.i < that.curLength) {
                    that.cur_tracks.push(false);
                    that.i = that.i + 1;
                }

                var width = 600;
                var height = 400;

                var svg = d3.selectAll("svg");

                var projection = d3.geoMercator()
                    .center([that.hsprofile.demographics.longitude, that.hsprofile.demographics.latitude])
                    .scale([800])
                    .translate([345,205])
                    .precision([1]);

                var path = d3.geoPath().projection(projection);

                var tooltip = d3.select("#mapsvgid")
                    .append("div")
                    .style("position", "absolute")
                    .style("background-color", "#303b49")
                    .style("visibility", "hidden")
                    .text("city");

                svg.selectAll(".path_china")
                .data(topojson.feature(china, china.objects.CHN_adm1).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_china");

                 svg.selectAll(".path_africa")
                .data(topojson.feature(africa, africa.objects.continent_Africa_subunits).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_africa");

                svg.selectAll(".path_asia")
                .data(topojson.feature(asia, asia.objects.continent_Asia_subunits).features) //plop output of this into main.marko and save that as window.asia
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_asia");

                svg.selectAll(".path_europe")
                .data(topojson.feature(europe, europe.objects.continent_Europe_subunits).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_europe");

                svg.selectAll(".path_north_america")
                .data(topojson.feature(north_america, north_america.objects.continent_North_America_subunits).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_north_america");

                svg.selectAll(".path_oceania")
                .data(topojson.feature(oceania, oceania.objects.continent_Oceania_subunits).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_oceania");

                svg.selectAll(".path_south_america")
                .data(topojson.feature(south_america, south_america.objects.continent_South_America_subunits).features)
                .enter()
                .append("path")
                .attr("d", path)
                .attr("stroke", "#181d23")
                .attr("stroke-width", .7)
                .attr("fill", "#303B49")
                .attr("width", width)
                .attr("height", height)
                .attr("class", "path_south_america");

                that.hs_city = [ that.hsprofile.demographics.longitude, that.hsprofile.demographics.latitude, that.hsprofile.demographics.population, that.hsprofile.demographics.gdp ];

                var curCity = that.hs_city
                var cities = that.hsprofile.demographics.filler
                var curLat = curCity[1]
                var curLong = curCity[0]
                var vicinity = []
                for (var i = 0; i < cities.length; i++) {
                    var city = cities[i]
                    if (Math.abs(city.filler_longitude - curLong) <= 20 && Math.abs(city.filler_latitude - curLat) <= 10) {
                        vicinity.push(city.filler_population)
                    }
                }
                var maxPopulation = vicinity.reduce(function(a, b) {
                    return Math.max(a, b);
                });
                var SCALAR = 10
                if (maxPopulation) {
                    SCALAR = Math.sqrt(maxPopulation) / 10
                }

                svg.selectAll(".hs_filler")
                .data(that.hsprofile.demographics.filler).enter()
                .append("circle")
                .attr("cx", function (d) { return projection([d.filler_longitude, d.filler_latitude])[0]; })
                .attr("cy", function (d) { return projection([d.filler_longitude, d.filler_latitude])[1]; })
                // .attr("r", function(d) { return (popFn(d.filler_population) + "px") }) //orange circles
                .attr("r", function(d) {
                    // var SCALAR = 600
                    // return (popFnWithCoords(d.filler_population, d.filler_longitude) + "px")
                    return (popFnWithScalar(d.filler_population, SCALAR) + "px")
                    })
                .attr("class", "hs_filler")
                .attr("stroke", function(d) {
                    switch(d.filler_type) {
                        case 1: // country
                            return "#e96724";
                        case 2: // province
                            return "#dee7e9";
                        default: // city and other
                            return "#181d23";
                    }})
                .attr("fill", function(d) { return +d.filler_population > +that.sortedByPopulation[5].filler_population ? that.code_orange : that.code_orange_light })
                .attr("stroke-width", 1)
                .on("mouseover", function(d){
                    if (that.popover_population) {
                        return tooltip.text(d.filler_name + " (" + Number(d.filler_population).toLocaleString() + ")")
                                    .style("visibility", "visible")
                                    .style("top", (projection([d.filler_longitude, d.filler_latitude])[1] - 20) +  "px")
                                    .style("left", (projection([d.filler_longitude, d.filler_latitude])[0] - (d.filler_name.length / 2 * 3)) +  "px")
                                    .style("color", that.code_orange)
                                    .style("z-index", "10");
                    }
                    if (that.popover_gdp) {
                        return tooltip.text(d.filler_name + " (" + Number(d.filler_gdp).toLocaleString() + ")")
                                    .style("visibility", "visible")
                                    .style("top", (projection([d.filler_longitude, d.filler_latitude])[1] - 20) +  "px")
                                    .style("left", (projection([d.filler_longitude, d.filler_latitude])[0] - (d.filler_name.length / 2 * 3)) +  "px")
                                    .style("color", that.code_blue)
                                    .style("z-index", "10");
                    }
                    })
                .on("mouseout", function(d){return tooltip.style("visibility", "hidden");});

                var peers = svg.selectAll(".hs_peer")
                .data(that.hsprofile.demographics.peers).enter()
                .append("g")
                .attr("class", "hs_peer")
                .attr("transform", function (d) {return "translate(" + projection([d.peer_longitude, d.peer_latitude])[0] + "," + projection([d.peer_longitude, d.peer_latitude])[1] + ")"});

                peers.append("circle")

                .attr("r", function(d) { return ((popFn(d.peer_population) + 8) + "px") })
                .attr("fill", "none")
                .attr("stroke", that.code_orange)
                .attr("class", "peer_ring2")
                .attr("stroke-width", 1);

                peers.append("circle")

                .attr("r", function(d) { return ((popFn(d.peer_population) + 4) + "px") })
                .attr("fill", "none")
                .attr("stroke", that.code_orange)
                .attr("class", "peer_ring1")
                .attr("stroke-width", 1);

                peers.append("circle")

                .attr("r", function(d) { return (popFn(d.peer_population) + "px") })
                .attr("stroke", "#181d23")
                .attr("stroke-width", .5)
                .attr("class", "peer_city")
                .attr("fill", that.code_orange);

                peers.append("rect")
                .attr("class", "peer_rect")
                .attr("y", "-7px")
                .attr("x", function(d) { return ((popFn(d.peer_population) + 10) + "px") })
                .attr("height", "18px")
                .attr("width", function(d) { return (d.peer_name.length * 7.5) + "px"})
                .attr("fill", "#303b49");

                peers.append("text")
                .attr("fill", "#e96724")
                .attr("class", "peer_text")
                .attr("y", "7px")
                .attr("x", function(d) { return ((popFn(d.peer_population) + 12) + "px") })
                .text(function(d) {return d.peer_name});

                svg.selectAll(".hs_city")
                .data([that.hs_city]).enter()
                .append("circle")
                .attr("cx", function (d) { return projection([d[0], d[1]])[0]; })
                .attr("cy", function (d) { return projection([d[0], d[1]])[1]; })
                .attr("r", function(d) { return (popFnWithScalar(d[2], SCALAR) + "px")  })
                .attr("stroke", "#181d23")
                .attr("stroke-width", .5)
                .attr("class", "hs_city")
                .attr("fill", that.code_orange);

                var hs_city_label = d3.select("#hscitylabelid")
                .append("div")
                .style("position", "absolute")
                .style("background-color", "#303b49")
                .style("color", that.code_orange)
                .attr("class", "city_label")
                .text(that.hsprofile.demographics.name + (that.hsprofile.demographics.population ? " (" + Number(that.hsprofile.demographics.population).toLocaleString() + ")" : ""))
                .style("left", (popFn(that.hs_city[2]) + 22 + projection([that.hs_city[0], that.hs_city[1]])[0]) + "px")
                .style("top", (projection([that.hs_city[0], that.hs_city[1]])[1] - 2) + "px");

                // hs[] = long/lat/pop/gdp

            });
        });
        },
        methods: {
            httpize: function(url) {
                if (url && url.indexOf("http") < 0) {
                    return "https://" + url;
                } else {
                    return url;
                }
            },
            formatNumberString: function (input) {
                return Number(input).toLocaleString();
            },
            checkScroll: function ($event) {
                var target = $($event.target).closest(".cur-wrapper").find(".panel-body")[0]
                if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
                    $(target).next().css("display", "none");
                } else {
                    $(target).next().css("display", "block");
                }
            },
            currentCity: function(current_city) {
                var that = this;
                return (current_city == that.hsprofile.demographics.name)
            },
            togglePopover: function() {
                if (this.hsprofile.demographics && this.hsprofile.demographics.show_comparison) {
                    this.popover_clicked = !this.popover_clicked
                } else {
                    this.popover_clicked = false;
                }
            },
            toggleGDP_POP: function() {
                this.popover_population = !this.popover_population;
                this.popover_gdp = !this.popover_gdp;
                this.toggle_map_data();
            },
            compare_pop: function() {
                this.city_comparison = true;
                if (this.popover_gdp) {
                    this.toggleGDP_POP();
                }
            },
            compare_gdp: function() {
                this.city_comparison = true;
                if (this.popover_population) {
                    this.toggleGDP_POP();
                }
            },
            exit_compare: function() {
                this.city_comparison = false;
            },
            logo_workaround: function(cur_id, $event) {
                var that = this;
                if (this.cur_tracks[cur_id] == true) {
                    Vue.set(that.cur_tracks, cur_id, false);
                    var temp = that.cur_tracks.slice(0, that.curLength);
                    that.cur_tracks = temp;
                }
                else {
                    Vue.set(that.cur_tracks, cur_id, true);
                    var temp = that.cur_tracks.slice(0, that.curLength);
                    that.cur_tracks = temp;
                }

                window.setTimeout(function() {
                    that.checkScroll($event);
                }, 400); // wait for .collapsing transition to finish
            },
            alertclickhsp: function() {
                this.alertclickedhsp = !this.alertclickedhsp;
            },
            alertSeen() {
                if (localStorage.alertSeen == 'true') {
                    return false
                }
                else {
                    return true
                }
            },
            alert_seen: function() {
                localStorage.alertSeen = 'true'
            },
            toggle_map_data: function() {
                var that = this;
                var svg = d3.selectAll("svg");

                var city = svg.selectAll(".hs_city");
                var city_label = d3.select(".city_label")

                var filler = svg.selectAll(".hs_filler");

                var peer_city = svg.selectAll(".peer_city");
                var peer_ring1 = svg.selectAll(".peer_ring1");
                var peer_ring2 = svg.selectAll(".peer_ring2");
                var peer_text = svg.selectAll(".peer_text");
                var peer_rect = svg.selectAll(".peer_rect");
                var t = d3.transition()
                    .duration(750);

                var projection = d3.geoMercator()
                    .center([that.hsprofile.demographics.longitude, that.hsprofile.demographics.latitude])
                    .scale([800])
                    .translate([345,205])
                    .precision([1]);

                // UPDATE old elements present in new data.
                if (this.popover_population) {
                    city.transition(t)
                    .attr("fill", this.code_orange)
                    .attr("r", function(d) { return (popFn(that.hsprofile.demographics.population) + "px") });

                    city_label
                    .transition(t)
                    .text(that.hsprofile.demographics.name + (that.hsprofile.demographics.population ? " (" + Number(that.hsprofile.demographics.population).toLocaleString() + ")" : ""))
                    .style("color", that.code_orange)
                    .style("left", (popFn(that.hs_city[2]) + 22 + projection([that.hs_city[0], that.hs_city[1]])[0]) + "px")

                    peer_ring2.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("stroke", this.code_orange)
                    .attr("r", function(d) { return ((popFn(d.peer_population) + 8) + "px") });

                    peer_ring1.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("stroke", this.code_orange)
                    .attr("r", function(d) { return ((popFn(d.peer_population) + 4) + "px") });

                    peer_city.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("fill", this.code_orange)
                    .attr("r", function(d) { return (popFn(d.peer_population) + "px") });

                    peer_rect.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("width", function(d) { return (d.peer_name.length * 7.5) + "px"})
                    .attr("x", function(d) { return ((popFn(d.peer_population) + 10) + "px") })

                    peer_text.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("fill", this.code_orange)
                    .attr("x", function(d) { return ((popFn(d.peer_population) + 12) + "px") })

                    filler.data(that.hsprofile.demographics.filler)
                    .transition(t)
                    .attr("fill", function(d) { return that.code_orange_light })
                    .attr("r", function(d) { return (popFn(d.filler_population) + "px") });
                }
                if (this.popover_gdp) {
                    city.transition(t)
                    .attr("fill", this.code_blue)
                    .attr("r", function(d) { return (gdpFn(that.hsprofile.demographics.gdp) + "px") });

                    city_label
                    .transition(t)
                    .text(that.hsprofile.demographics.name + (that.hsprofile.demographics.gdp ? " (" + Number(that.hsprofile.demographics.gdp).toLocaleString() + ")" : ""))
                    .style("color", that.code_blue)
                    .style("left", (gdpFn(that.hs_city[3]) + 12 + projection([that.hs_city[0], that.hs_city[1]])[0]) + "px")

                    peer_ring2.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("stroke", this.code_blue)
                    .attr("r", function(d) { return ((gdpFn(d.peer_gdp) + 8) + "px") });

                    peer_ring1.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("stroke", this.code_blue)
                    .attr("r", function(d) { return ((gdpFn(d.peer_gdp) + 4) + "px") });

                    peer_city.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("fill", this.code_blue)
                    .attr("r", function(d) { return (gdpFn(d.peer_gdp) + "px") });

                    peer_rect.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("width", function(d) { return (d.peer_name.length * 7.5) + "px"})
                    .attr("x", function(d) { return ((gdpFn(d.peer_gdp) + 10) + "px") })

                    peer_text.data(that.hsprofile.demographics.peers)
                    .transition(t)
                    .attr("fill", this.code_blue)
                    .attr("x", function(d) { return ((gdpFn(d.peer_gdp) + 12) + "px") })

                    filler.data(that.hsprofile.demographics.filler)
                    .transition(t)
                    .attr("fill", function(d) { return that.code_blue_light })
                    .attr("r", function(d) { return (gdpFn(d.filler_gdp) + "px") });
                }
            },

            fetchVideoMetadata: function () {
                var that = this;
                ivbus.getFlag("hsprofile").then(function () {
                    if (that.hsprofile.cadenza) {
                        that.videometa = JSON.parse(JSON.stringify(that.hsprofile.cadenza));
                        ivbus.$emit('meta-ready' + that.eventns, that.videometa);
                    }
                });
            },

            selectQuestion: function (q) {
                ivbus.$emit("iv-player-timetravel" + this.eventns, q.time)
                this.setCurrentVideoQuestionId(q.index); // q.index and currentVideoQuestionId are both 1-based
            },

            setCurrentVideoQuestionId: function (i) {
                this.currentVideoQuestionId = i; // i+1 if event comes from iv-player?
            },

            loadOtherProfile: function (other) {
                this.$emit('load-other-profile', other.unique_id)
            },

            sendMessage: function () {
                var that = this;
                var spinId = that.spinStart();
                apiClient.post("/api/hsprofile/message/?v=" + getParameterByName('v'), {from: that.messageFrom, name: that.messageFromName, message: that.messageContent}).then(function(response) {
                    that.spinStop(spinId);
                    if (response.data.status == 200) {
                        that.messageSent = true;
                    }
                }).catch(function() {
                    that.spinStop(spinId);
                })
            }

        }
    }
</script>

<style scoped>
.row-short {
    margin-top: 5px;
    margin-bottom: 5px;
}
.row-m0 {
    margin-top: 0;
    margin-bottom: 0
}
.alert-hsprofile {
    background-color: #F7A945;
    text-align: center;
    padding: 35px;
    text-align: center;
    z-index: 1000;
}
.alert-title {
    font-size: 1.5em;
    background-color: #F7A945;
    color:black;
    font-weight: bold;
}
.alert-black-text {
    color: #000;
    font-weight: bold;
    font-size: 1.5em;
}
.alert-gray-text {
    color: #fffefd;
    font-weight: bold;
    font-size: 1.5em;
}
.alert-hsprofile-button {
    background-color: #fffefd;
    border-radius: 10px;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 10px;
    padding: 10px 35px;
}
.website-icon {
    color:#003349;
    vertical-align:middle;
    margin-left: 15px;
    margin-right: 5px;
}
.bluetext {
    color: #003349;
    font-size: 2em;
    font-weight: bold;
    margin: 0;
}
.orangetext {
    color: #e96724;
    font-size: 1.5em;
}
.bluebannertext {
    margin: 0;
    color: #fff;
    background-color: #003349;
    font-size: 1.4em;
    padding:5px;
}
.sidebar {
    background-color: #DEE7E9;
}
.sidebarheading {
    color: #003349;
    font-size: 1.5em;
    font-weight: bold;
    margin: 18px 12px 4px;
}
.sidebartext {
    margin: 0px 12px;
    color:#003349;
    font-size: 1.5em;
}
.curricula {
    border: 1px solid #e96724;
}
.cur-icon {
    color: #DEE7E9;
    float: right;
    margin: 2px 12px 0px 0px;
}
.panel-body {
    margin: -12px 0px 12px 0px;
}
.panel-blue {
    border-color: rgb(0, 51, 73);
}
.panel-blue .panel-heading {
    background-color: rgb(0, 51, 73);
    color: #fff;
}
.btn-blue {
    background-color: rgb(0, 51, 73);
    color: #fff;
}
.courses {
    color:#003349;
}
.graytext {
    font-size: 1.4em;
    color: #272822;
    margin: 0 12px;
    padding: 5px;
}
.counselor-container {
    padding-bottom:15px;
}
.counselor-info {
    font-size: 1.4em;
    color: #003349;
    margin: 12px;
}
.counselor-icon {
    color:#003349;
    vertical-align:middle;
    margin-right: 12px;
}
.map-background {
    background-color: #DEE7E9;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 2px;
    height: 430px;
    padding:10px;
    position: relative;
    left: 35px;
    width: 705px;
}
.map {
    width: 600px;
    height: 400px;
    margin:15px;
}
.map-icon {
    width: 25px;
    top: 210px;
    position: absolute;
    left: 352px;
    transform: translate(-12px,-35px);
}
svg {
    width: 100%;
    height: 100%;
    position: center;
    overflow: hidden;
}
.data-popover {
    width: 490px;
    height: 270px;
    left: 140px;
    top: 70px;
    position: absolute;
    z-index: 2;
}
.popover-content {
    background-color: #DEE7E9;
    height: 270px;
}
.data-population {
    border: solid 5px #e96724;
}
.data-gdp {
    border: solid 5px #83A19e;
}
.popover_city {
    color: #303b49;
    font-weight: bold;
    font-size: 1.8em;
}
.popover_text {
    color: #303b49;
    font-weight: bold;
    font-size: 1.2em;
}
.pop_title {
    color: #e96724;
    font-size: 1.3em;
    margin-top: 10px;
}
.gdp_title {
    color: #83A19e;
    font-size: 1.3em;
    margin-top: 10px;
}
.map_pop_title {
    color: #e96724;
    font-size: 1.5em;
    font-weight: bold;
    z-index: 1;
}
.click_pop {
    color: #e96724;
    font-weight: bold;
    z-index: 1;
    float: right;
    font-size: 1.3em;
    margin-top: 0.1em;
}
.map_gdp_title {
    color: #83A19e;
    font-size: 1.5em;
    font-weight: bold;
    z-index: 1;
}
.click_gdp {
    color: #83A19e;
    font-weight: bold;
    z-index: 1;
    float: right;
    font-size: 1.3em;
    margin-top: 0.1em;
}
.popup-button-holder {
    display: flex;
    background-color: #ffffff;
    justify-content: space-evenly;
    border-radius: 20px;
    margin: 15px;
}
.compare-button-icon {
    color: #303b49;
    font-size: 2em;
    padding: 6px;
}
.compare-button-text {
    color: #303b49;
    font-weight: bold;
    width: 50%;
}
.exit-compare-icon {
    font-size: 2em;
    color: #303b49;
}
.compare-text-orange {
    font-weight: bold;
    color: #e96724;
}
.compare-text-blue {
    font-weight: bold;
    color: #83A19e;
}
.comparison-lists {
    display:flex;
    overflow:auto;
    max-height: 130px;
}
.listed-text {
    color: #303b49;
    font-weight: bold;
    width: 120%;
}
.popover-clicked {
    margin-top: -10px;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0
}
.hidden {
      display: none;
}
div.tooltip {
      color: #222;
      background: #fff;
      border-radius: 3px;
      box-shadow: 0px 0px 2px 0px #a6a6a6;
      padding: .2em;
      text-shadow: #f5f5f5 0 1px 0;
      opacity: 0.9;
      position: absolute;
      background-color: #303b49;
}
/* video question override styles */
.question, #questions ul li div div.question {
    color: #303b49;

}
#questions li:first-child {
    margin-top:0px;
}
.questionParent, #questions ul li div#questionParent {
    background-color: #DEE7E9;
    border: 1px solid  #303b49;
}
#questions {
    box-shadow: unset;
    height: unset !important;
}
.blue-cam {
    width: 35px;
    position: relative;
    top: 25px;
    left: -15px;
}
.blue-cam-number {
    top: 4px;
    left: -7px;
    font-size: 1em;
    color: #fff;
    position: relative;
}

/* d3 styles */
.hs_city_text {
    background-color: #303b49;
}
</style>
