<template>
<div>
	<div>
		<div class='row'>
			<div class='col-md-12'>
				<h3>High School Rankings</h3>
			</div> 
		</div>
		<div class='container' id='list-view' style="min-width:1394px;top:151px">
			<div>
				<div class="modal-content" style="border-radius:0px;display:flex">
					<div style="width:50%">
						<div class="modal-header">
							<div class="text-center" style="color:#e96724;text-transform:uppercase;font-size: 1.25em;">
								Districts and Cities
							</div>
						</div>
						<div class="modal-body">
							<div class="row">
								<div class="col-md-12">
									<table class="table table-bordered">
										<thead>
											<tr>
												<th>
													District or City (Click to see High Schools by rank)
													<input type="text" class="form-control" v-model="filter_name">
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="group in filteredCity" v-bind:key="group.label">
												<td @click="getRankInfo(group)" style="cursor:pointer">
													{{group.label}}
												</td>
											</tr>
										</tbody>         
									</table>
								</div>
							</div>
						</div>
					</div>
					<div v-if="selected_area" style="width:50%">
						<div class="modal-header">
							<div class="text-center" style="color:#e96724;text-transform:uppercase;font-size: 1.25em;">Rankings Within {{selected_area}}</div>
						</div>                
						<div class="modal-body">
							<div class="row">
								<div class="col-md-12">
									<table class="table table-bordered">
										<thead>
											<tr>
												<th>
													Rank
												</th>
												<th>
													English Name
												</th>
												<th>
													Chinese Name
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="ranking in ranking_info" v-bind:key="ranking">
												<td>
													{{ranking.rank}}
												</td>
												<td>
													{{ranking.label}}                                    
												</td>
												<td>
													{{ranking.label_cn}}
												</td>
											</tr>
										</tbody>         
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>

import Vue from 'vue';
import {
	ivSpinnerMixin,
	ivAlertMixin,
} from "@/mixins/base-components-pack.js";
import moment from 'moment-timezone';
import ivbus from "@/utils/ivbus.js";
import apiClient from "../utils/apiClient.js";
import env from "../utils/env.js";

export default {
	props: [
		'ameliaaddr',
	],
	mixins: [ivSpinnerMixin],
	components: {
	},
	data() {
		return {
			rank_groupings: [],
			selected_area: "",
			ranking_info: {},
			filter_name: "",
			sortOrder: 1, //just to keep it happy...

			$_startIndex: 0,
			itemContainerStyle: null,
			itemsStyle: null,
			scroll: {
				top: 0,
				bottom: 20,
			},
		}
	},
	mounted () {
		var that = this;
		that.getRankGroupings();
	},
	computed: {
		filteredCity: function () {
			var that = this;
			return that.rank_groupings.filter(function (rg) {
				var match = true;
				if (match && that.filter_name) {
					match = rg.label && rg.label.toLowerCase().indexOf(that.filter_name.toLowerCase()) !== -1;
				}
				return match;
			});
		},
	},

	methods: {

		handleScroll: function () {
			// console.log("SCROLL IS");
			var el = $("#scrollableSearch")[0];
			var scroll = {
				top: el.scrollTop,
				bottom: el.scrollTop + el.clientHeight
			};

			if (scroll.bottom >= 0 && scroll.top <= scroll.bottom) {
				Vue.set(this, "scroll", scroll);
				// console.log(scroll);
			} else {
				Vue.set(this, "scroll", null);
			}
		},
		getRankGroupings: function() {
			var that = this;
			var spinId = this.spinStart();
			apiClient.get('/api/hsprofile/get_rank_groupings/').then(function (response) {
				that.spinStop(spinId);
				if (response.data.status == 200) {
					that.rank_groupings = response.data.data;
				}
				else {
					console.log(response.data.data);
					that.showMessage('There was a problem with our server. Please try again. If the problem persists, please let us know at amelia@initialview.com!', 'alert-danger', 3000);
				}
			}).catch(function(error) {
				that.spinStop(spinId);
				console.log(error);
				that.showMessage('There was a problem communicating with our server. Please try again. If the problem persists, please let us know at amelia@initialview.com!', 'alert-danger', 3000);
			})
		},
		getRankInfo: function(rg) {
			var that = this;
			that.selected_area = rg.label;
			var spinId = this.spinStart();
			apiClient.get('/api/hsprofile/get_dep_rankings/', {params: {"rank_grouping_id": rg.id}}).then(function (response) {
				that.spinStop(spinId);
				if (response.data.status == 200) {
					that.ranking_info = response.data.data;
				}
				else {
					console.log(response.data.data);
					that.showMessage('There was a problem with our server. Please try again. If the problem persists, please let us know at amelia@initialview.com!', 'alert-danger', 3000);
				}
			}).catch(function(error) {
				that.spinStop(spinId);
				console.log(error);
				that.showMessage('There was a problem communicating with our server. Please try again. If the problem persists, please let us know at amelia@initialview.com!', 'alert-danger', 3000);
			})
		},
	}
}
	
</script>

<style lang="scss">

.modelInput {
  border: solid 2px #6a605f;
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 15px;
}

.btn-submit-message {
  color: white;
  background-color: #6a605f;
  padding: 10px 40px;
  border-radius: 14px;
  font-size: 124%;
  font-weight: lighter;
}

</style>