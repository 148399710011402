<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-offset-2 col-md-8">
        <p
          class="inviteRotate"
          :class="setup.inviteRotateStyle"
          :style="setup.inviteRotatePlacement"
        >{{ setup.modalLabel }}</p>
        <p
          class="modalLabel"
          :class="setup.modalLabelStyle"
          style="margin-left: 40px;"
        >{{ setup.sendToLabel }}</p>
        <span v-if="setup.sendNumber == 'single'">
          <span
            style="padding:5px 10px; font-size:115%; margin-left:25px;"
            v-if="student_lastName != ', '"
          >{{student_lastName}}, {{student_firstName}} ({{student_email}})</span>
          <span
            style="padding:5px 10px; font-size:115%; margin-left:25px;"
            v-else-if="student_email != ''"
          >{{student_email}}</span>
        </span>
        <div class="form-group" style="margin-left:25px;">
          <textarea
            v-if="setup.sendNumber == 'multiple'"
            class="modalInput form-control"
            :class="setup.modalInputStyle"
            v-model="inviteEmails"
            rows="3"
            placeholder="student1@example.com, student2@example.com"
          ></textarea>
        </div>
        <p
          class="modalLabel"
          :class="setup.modalLabelStyle"
          style="margin-left: 40px;"
        >{{ setup.subjectLabel}}</p>
        <div class="form-group" style="margin-left:25px;">
          <textarea
            class="modalInput form-control"
            :class="setup.modalInputStyle"
            style="margin-bottom:0px;"
            v-model="email.subject"
            :rows="1"
          ></textarea>
        </div>
        <p
          class="modalLabel"
          :class="setup.modalLabelStyle"
          style="margin-left: 40px;"
        >{{ setup.messageLabel}}</p>
        <div class="form-group" style="margin-left:25px;">
          <textarea
            class="modalInput form-control"
            :class="setup.modalInputStyle"
            style="margin-bottom:0px;"
            v-model="email.message"
            :rows="email.messageRows"
          ></textarea>
          <span
            style="margin-left: 15px;font-style:italic;"
            v-if="setup.context=='selfieAssessmentInvite'"
          >[a unique link will be automatically included in email]</span>
          <div 
            v-if="setup.context=='feedbackEmail'"
            style="margin-left: 15px;font-style:italic;"
          >[URL] will be automatically replaced with the student's unique access URL
          </div>
          <textarea
            class="modalInput form-control"
            :class="setup.modalInputStyle"
            v-if="email.message2"
            v-model="email.message2"
            :rows="email.messageRows2"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-offset-2 col-md-8" style="margin-bottom:25px;">
        <span
          v-show="!isSending"
          class="btn-submit-message pull-right"
          :class="setup.btnStyle"
          @click="sendEmail()"
          style="margin-left: 40px;"
        >SEND</span>
        <div class="button-spinner">
          <Spinner class="save-spinner" spinkey="emailSending"></Spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

import {
  ivSpinnerMixin,
  ivAlertMixin,
  getParameterByName,
  ivbus
} from "iv-base-components";

import Spinner from "./Spinner.vue";

import apiClient from "../utils/apiClient.js";

export default {
  mixins: [ivSpinnerMixin, ivAlertMixin],

  components: {Spinner},

  props: ["setup", "student_lastName", "student_firstName", "student_email"],

  data() {
    return {
      isSending: false,

      inviteEmails: "",

      email: {
        subject: "",
        message: "",
        messageRows: 1
      },
      configuration: {
        source_org_type: "hs"
      },
    };
  },

  mounted: function() {
    var that = this;
    window.setTimeout(function() {
      that.messageDefault();
    });
    that.emailValidator = new VeeValidate.Validator({
      email: "required|email"
    });
    ivbus.getFlag("configuration").then(function(configuration) {
      that.$set(that, "configuration", configuration);
      that.messageDefault();
    });
  },

  methods: {
    messageDefault: function() {
      if (this.setup.context == "interviewInvite") {
        if (this.configuration.source_org_type == 'cbo') {
          this.email = this.glimpseInviteMessageDefault();
        } else {
          this.email = this.interviewInviteMessageDefault();
        }
      } else if (this.setup.context == "selfieAssessmentInvite") {
        this.email = this.selfieAssessmentInviteMessageDefault();
      } else {
        //(this.setup.context == "blankEmail")
        this.email = this.blankEmailMessageDefault();
      }
    },

    interviewInviteMessageDefault: function() {
      return {
        subject: "Sign up for your InitialView interview!",
        message:
          "Hello!\n\nJust a friendly reminder to make sure that you have signed up for your InitialView interview (here's the link: initialview.com/booking). And, don't forget to include me as your 'Counselor/Trusted Advisor' if you would like feedback!\n\nRespond to this email if you have any questions or any issues with signing up. You can reach out to InitialView directly if you are not able to find a convenient time (contact@initialview.com).\n\nMake sure to sign up as soon as possible!\n\nGood luck!\n\n" +
          this.setup.counselor_first_name +
          " " +
          this.setup.counselor_last_name +
          "\n" +
          this.setup.counselor_email,
        messageRows: 16
      };
    },

    glimpseInviteMessageDefault: function() {
      return {
        subject: "Sign up for your InitialView Glimpse!",
        message:
          "Hello!\n\nStrengthen your college application by sending colleges your Glimpse, a 90-second video statement. Go to initialview.com/glimpse to record and send this video recording of yourself. \n\nGood luck!\n\n" +
          this.setup.counselor_first_name +
          " " +
          this.setup.counselor_last_name +
          "\n" +
          this.setup.counselor_email,
        messageRows: 16
      };
    },

    selfieAssessmentInviteMessageDefault: function() {
      return {
        subject: this.setup.counselor_first_name + " " + this.setup.counselor_last_name + " has invited you to do a Selfie Assessment",
        message:
          "Hello!\n\nWelcome to your 'Selfie Assessment'!\n\n" +
          "I’ve prepared some questions for you to answer that will be recorded with your webcam. While not dynamic and conversational like a real interview, the goal of this exercise is to get you to think on your feet and consider the types of questions you might be asked in an InitialView interview or alumni or admission officer interview.\n\nSome important instructions before you get started:\n\n" +
          "1) You’ll need a laptop computer with a webcam. Please use a Chrome browser to access the link below.\n" +
          "2) Read the text of each question and answer while speaking to the camera. You’ll have 120 seconds to answer each question, but you can continue to the next question if you are done early.\n" +
          "3) You’ve got one chance to answer each question, so just be yourself and answer the questions to the best of your ability.\n" +
          "4) The video will automatically be sent to me once you are done for us to discuss later.\n\n" +
          "Let’s do this! You can use this link to start your Selfie Assessment:",
        messageRows: 18,
        message2:
          "Good luck!\n\n" +
          this.setup.counselor_first_name +
          " " +
          this.setup.counselor_last_name +
          "\n" +
          this.setup.counselor_email,
        messageRows2: 4
      };
    },

    blankEmailMessageDefault: function() {
      return {
        subject: "Feedback on your Selfie Assessment",
        message:
          "Hello " +
          this.student_firstName +
          ",\n\n" +
          "I've been reviewing your Selfie Assessment and have some feedback for you. Click this link to log into your InitialView account and view the Selfie Assessment video you recorded: [URL]\n\n" +
          this.setup.counselor_first_name +
          " " +
          this.setup.counselor_last_name +
          "\n" +
          this.setup.counselor_email,
        messageRows: 10
      };
    },

    sendEmail: function() {
      var that = this;
      that.isSending = true;
      var spinId = this.spinStart({ spinkey: "emailSending" });

      if (that.setup.sendNumber == "single") {
        that.inviteEmails = that.student_email;
      }

      // tokenize by comma. strip spaces and validate email structure
      var tokenizedEmails = that.inviteEmails.split(",").map(function(te) {
        return te.trim();
      });
      var validations = [];
      tokenizedEmails.forEach(function(te) {
        validations.push(that.emailValidator.validate("email", te));
      });

      Promise.all(validations).then(function(results) {
        var valid = true;
        results.forEach(function(result) {
          valid = valid && result;
        });

        if (valid) {
          var postTo = "";
          var postObj = {};
          // if (that.setup.context == "interviewInvite") {
          //   postTo = "/api/sourceorg/invite_student/";
          //   postObj = {
          //     student_emails: tokenizedEmails,
          //     invite_text: that.email.message,
          //     invite_subject: that.email.subject
          //   };
          // } else 
          if (that.setup.context == "selfieAssessmentInvite") {
            postTo = "/api/user/invite_amelia_cadenza/";
            postObj = {
              student_emails: tokenizedEmails,
              invite_text1: that.email.message,
              invite_text2: that.email.message2,
              invite_subject: that.email.subject
            };
          } 
          // else if (that.setup.context == "feedbackEmail") {
          //   postTo = "/api/sourceorg/selfieassessment_feedback/?v=" + getParameterByName("v");
          //   postObj = {
          //     email_address: tokenizedEmails[0],
          //     subject: that.email.subject,
          //     message: that.email.message
          //   };
          // } else if (that.setup.context == "blankEmail") {
          //   postTo = "/api/sourceorg/counselor_email_student/";
          //   postObj = {
          //     email_address: tokenizedEmails[0],
          //     subject: that.email.subject,
          //     message: that.email.message
          //   };
          // }
          apiClient.post(postTo, postObj).then(function(request) {
            if (request.data.status == 200) {
              that.showMessage(
                "Email Sent!",
                "alert-success",
                that.uiMessageWaitLong
              );
              that.inviteEmails = "";
              that.messageDefault();
              that.spinStop(spinId);
              that.isSending = false;
              ivbus.$emit("refresh-list", "yo");
            } else {
              that.showMessage(
                "Email Not Successful",
                "alert-danger",
                that.uiMessageWaitLong
              );
              that.spinStop(spinId);
              that.isSending = false;
            }
          });
        } else {
          that.showMessage(
            "Invalid email format - ensure all email addresses are valid and separated by commas.",
            "alert-danger",
            that.uiMessageWaitLong
          );
          that.spinStop(spinId);
          that.isSending = false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$orange: #e96724;
$turquoise: #81aeaa;
$lightturquoise: #f1fafb;
$darkplum: #6a605f;
$lightgrey: rgb(229, 229, 229);
$medgrey: rgb(203, 203, 203);

.orangeText {
  color: $orange;
}

.orangeBorder {
  border: solid 2px $orange;
}

.orangeButton {
  background-color: $orange;
}

.plumText {
  color: $darkplum;
}

.plumBorder {
  border: solid 2px $darkplum;
}

.plumButton {
  background-color: $darkplum;
}

.modalLabel {
  margin-top: 22px;
  font-size: 120%;
}

.modalInput {
  border-radius: 15px;
  margin-bottom: 25px;
  padding: 15px;
  background-color: white !important;
}

.inviteRotate {
  font-size: 325%;
  font-weight: lighter;
  transform: rotate(270deg);
  position: absolute;
  top: 110px;
  left: -127px;
}

.btn-submit-message {
  color: white;
  padding: 10px 40px;
  border-radius: 14px;
  font-size: 124%;
  font-weight: lighter;
}
</style>