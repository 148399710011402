<template>
  <!-- the global spinner should be centered and have a small container around it. Others should have custom positioning. -->
  <div v-show="count > 0">
    <div v-if="myspinkey == 'global'">
      <div class="spinner-backdrop">
        <div class="spinner-wrapper">
          <div class="spinner-container">
            <div class="iv-spinner"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="myspinkey != 'global'">
      <div class="iv-spinner"></div>
    </div>
  </div>
</template>

<script>
import ivbus from "../utils/ivbus.js";

export default {
  props: ["spinkey"],
  data: function() {
    return {
      message: "Welcome to InitialView!",
      submessage: "Loading student portal...",
      myspinkey: this.spinkey,

      spinnerId: 0,
      spinnersById: {},
      spinnerMetadata: {},
      count: 0
    };
  },
  created: function() {
    var that = this;
    if (!that.myspinkey) {
      that.myspinkey = "global";
    }
    ivbus.takeSemaphore("iv-spinner" + that.myspinkey, true).then(function() {
      ivbus.onQ("ivSpinStart", that.show);
      ivbus.onQ("ivSpinStop", that.hide);
    });
  },
  beforeDestroy: function() {
    ivbus.releaseSemaphore("iv-spinner" + this.myspinkey);
  },
  methods: {
    checkSpinkey: function(config) {
      return (
        (config && config.spinkey && config.spinkey == this.myspinkey) ||
        ((!config || (config && !config.spinkey)) && this.myspinkey == "global")
      );
    },
    show: function(config) {
      if (this.checkSpinkey(config)) {
        this.spinnerId += 1;

        this.count += 1;
        this.spinnersById[this.spinnerId] = true;
        this.spinnerMetadata[this.spinnerId] = config ? config.metadata : null;

        config.spinnerId = this.spinnerId;
      }
    },
    hide: function(config) {
      if (this.checkSpinkey(config)) {
        if (!config || !this.spinnersById[config.spinnerId]) {
          console.log(
            "spinnerId " +
              config.spinnerId +
              " on " +
              this.myspinkey +
              " is not active."
          );
          return;
        }
        this.spinnersById[config.spinnerId] = false;
        this.count -= 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$orange: #e96724;
$turquoise: #81aeaa;
$lightturquoise: #f1fafb;
$darkplum: #6a605f;
$lightgrey: rgb(229, 229, 229);
$medgrey: rgb(203, 203, 203);
/*********** Spinners ***********/

.save-spinner .iv-spinner {
  position: absolute;
  top: 82%;
  left: 97%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid #adadad;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.table-save-spinner .iv-spinner {
  position: relative;
  top: -5px;
  left: 93%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid #adadad;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.summary-table-save-spinner .iv-spinner {
  position: relative;
  top: 28px;
  left: 70%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid #adadad;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.button-spinner {
  height: 34px;
}

/* spinner */

.spinner-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9050;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.spinner-wrapper {
  position: relative;
  top: 45%;
  left: 50%;
}

#global-spinner .spinner-container {
  top: -75px;
  left: -125px;
  width: 250px;
  height: 150px;
  border-radius: 20px;
}

#global-spinner .iv-spinner {
  top: 72.5%;
}

.spinner-container {
  position: absolute;
  top: -35px;
  left: -35px;
  background: #686868;
  color: #fff;
  float: left;
  width: 70px;
  height: 70px;
  border-radius: 14px;
  opacity: 0.95;
}

.iv-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: 0.85;
  text-align: left;
  border: 6px solid #adadad;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 50px;
  height: 50px;
  border-radius: 25px;
}

.iv-spinner:before,
.iv-spinner:after {
  content: "";
  position: absolute;
  margin: -6px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 1;
  border: inherit;
  border-color: transparent;
  border-top-color: white;
}

.iv-spinner:before,
.iv-spinner:after {
  -webkit-animation: iv-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8)
      infinite,
    iv-spinner-fade 1.1s linear infinite;
  animation: iv-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    iv-spinner-fade 1.1s linear infinite;
}

.iv-spinner:before {
  border-top-color: white;
}

.iv-spinner:after {
  border-top-color: white;
  -webkit-animation-delay: 0.44s;
  animation-delay: 0.44s;
}

@keyframes iv-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes iv-spinner-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes iv-spinner-fade {
  0% {
    border-top-color: $turquoise;
  }

  20% {
    border-top-color: $turquoise;
  }

  35% {
    border-top-color: $orange;
  }

  60% {
    border-top-color: $turquoise;
  }

  100% {
    border-top-color: $turquoise;
  }
}

@-webkit-keyframes iv-spinner-fade {
  0% {
    border-top-color: $turquoise;
  }

  20% {
    border-top-color: $turquoise;
  }

  35% {
    border-top-color: $orange;
  }

  60% {
    border-top-color: $turquoise;
  }

  100% {
    border-top-color: $turquoise;
  }
}
</style>

